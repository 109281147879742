export { matchers } from './client-matchers.js';

export const components = [
	() => import("../runtime/components/layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/manage-trade.svelte"),
	() => import("../../src/routes/manage-spec.svelte"),
	() => import("../../src/routes/about.svelte")
];

export const dictionary = {
	"": [[0, 2], [1]],
	"manage-trade": [[0, 3], [1]],
	"manage-spec": [[0, 4], [1]],
	"about": [[0, 5], [1]]
};